uniform float time;
uniform float progress;
uniform sampler2D uDataTexture;
uniform sampler2D uTexture;
uniform vec4 resolution;
varying vec2 vUv;
varying vec3 vPosition;
varying vec3 customColor;
varying vec3 vColor;
varying float vOpacity;
uniform vec3 uColor;

float PI = 3.141592653589793238;
void main()	{
	// vec2 newUV = (vUv - vec2(0.5))*resolution.zw + vec2(0.5);
	vec2 uv = vec2(gl_PointCoord.x, 1. - gl_PointCoord.y);
	vec2 cUV = 2.*uv - 1.;

	// vec3 uColor = vec3(1.,0.,0.);

	// Control Particles better 
	vec3 originalColor = vec3(40./255., 40./255., 40./255.);

	vec4 color = vec4(0.08/length(cUV));

	color.rgb = min(vec3(10.), color.rgb);

	color.rgb *= originalColor*120.;

	// Aktualisieren Sie die Base-Color basierend auf JS (uColor)
    // color.rgb *= originalColor * uColor;
    color.rgb *= originalColor * vColor;

	color.rgb *= vOpacity;

	color.a = min(1., color.a)*10.;

	// Typical Case for set particles with glow 
	float disc = length(cUV);


	// vec4 color = texture2D(uTexture,newUV);
	// vec4 offset = texture2D(uDataTexture,vUv);
	gl_FragColor = vec4(1. - disc,0.,0.,1.) * vOpacity; // vec4(vUv,0.0,1.)
	gl_FragColor = vec4(color.rgb * vColor,color.a) * vOpacity ; // vec4(vUv,0.0,1.)
	// gl_FragColor = vec4(offset.r,0.,0.,1.);
	// gl_FragColor = color;
	// gl_FragColor = texture2D(uTexture,newUV - 0.02*offset.rg);
	// gl_FragColor = offset;
}