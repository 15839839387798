console.log("app.js loaded")

import * as THREE from "three"
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls.js"
import GUI from "lil-gui"

import fragment from "./shader/fragment.glsl"
import vertex from "./shader/vertexParticles.glsl"
// import gsap from "gsap"
import is from "sharp/lib/is"

/**
 * Base
 */
// Debug
const gui = new GUI()

// Canvas
const canvas = document.querySelector("#canvasContainer > canvas.webgl")

// Scene
const scene = new THREE.Scene()
// green bg
scene.background = new THREE.Color(0x040e1c) // THREE.Color(0x041f1a)

/**
 * Sizes
 */
const sizes = {
	width: window.innerWidth,
	height: window.innerHeight,
}

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 10000)
camera.position.x = 0
camera.position.y = 0
camera.position.z = 600
scene.add(camera)

scene.fog = new THREE.Fog(0x040e1c, 0, 1000)

// const fogGeometry = new THREE.BoxGeometry(100, 100, 100)
// const fogMaterial = new THREE.MeshBasicMaterial({color: 0xcccccc})
// const fogCube = new THREE.Mesh(fogGeometry, fogMaterial)
// fogCube.position.y = -160
// scene.add(fogCube)

// Controls
// const controls = new OrbitControls(camera, canvas)
// controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
	canvas: canvas,
	// alpha: true,
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Data from UI
 */
// active sections
let isSectionOneActive = true
let isSectionTwoActive = false
let isSectionThreeActive = false

// mouse information
const mouse = {
	x: null,
	y: null,
}
// Lupe

const lupe = document.querySelector(".lupe")
let lupeSize = (0 / window.innerWidth) * 2048 // * 0.86
// console.log("lupeSize: ", lupeSize)
// lupe.style.transform = `translate(${200}px, ${200}px)`
let isLupeActive = false
// console.log("lupe initilized")

document.addEventListener("mousemove", handleMouseMove)
document.addEventListener("scroll", setLupe)

function handleMouseMove(event) {
	// mouse.x = event.clientX - window.innerWidth / 2
	// mouse.y = event.clientY - window.innerHeight / 2

	// convert mouse.x and mouse.y to the same coordinate system as the points
	mouse.x = (event.clientX / window.innerWidth) * 2048 - 1024
	mouse.y = (event.clientY / window.innerHeight) * 1024 - 512
	// setLupe()
}

// set Interval for calling setLupe() each 100ms
// setInterval(setLupe, 100)

function setLupe() {
	console.log("isSectionTwoActive", isSectionTwoActive)

	// // Guard
	// if (!isSectionTwoActive && !isLupeActive) return
	// Reset isLupeActive
	if (!isSectionTwoActive && isLupeActive) {
		console.log("isLupeActive === false")
		isLupeActive = false
		lupe.style.width = 0
		lupe.style.height = 0
		lupe.style.transform = `translate(${200}px, ${200}px)`
		lupe.classList.remove("active")
	}
	// Activate isLupeActive
	if (!isLupeActive && isSectionTwoActive) {
		console.log("isLupeActive === true")
		isLupeActive = true
		lupe.style.width = lupeSize + "px"
		lupe.style.height = lupeSize + "px"
		lupe.style.transform = `translate(${0}, ${0})`
		lupe.classList.add("active")
	}

	// Lupe position
	lupe.style.left = mouse.x - lupeSize / 2 + "px"
	lupe.style.top = mouse.y - lupeSize / 2 + "px"
}

// Get SVG Data from HTML
let lines = []
let tickAnimation = true
let svgDataSectionOne = [...document.querySelectorAll(".cls-1")]
let svgDataSectionTwo = [...document.querySelectorAll(".cls-2")]
const combinedArray = svgDataSectionOne.concat(svgDataSectionTwo)
const getSVGDataForSectionOne = () => {
	// console.log(svg)

	lines = []

	// use SVG API to get the path data
	combinedArray.forEach((path, index) => {
		const pathLength = path.getTotalLength()

		// create a new array with the path data
		// Create the number of points by the path length
		let numberOfPoints = Math.floor(pathLength / 5)
		// console.log("numberOfPoints", numberOfPoints)

		let points = []

		// Get X and Y coordinates of the path
		for (let i = 0; i < numberOfPoints; i++) {
			//   let point = path.getPointAtLength(i * pathLength / numberOfPoints)
			//   points.push(point.x, point.y, point.z)
			let pointAt = (pathLength * i) / numberOfPoints
			let point = path.getPointAtLength(pointAt) // get the x and y coordinates at the point
			// console.log("point", point)
			let randomX = (Math.random() - 0.5) * 0
			let randomY = (Math.random() - 0.5) * 0
			points.push(new THREE.Vector3(point.x - 1024 + randomX, point.y - 512 + randomY, 1))
		}

		lines.push({
			id: index,
			path: path,
			length: pathLength,
			number: numberOfPoints,
			points: points,
			currentPosition: 0,
			speed: 1,
		})
	})

	console.log("lines", lines)
	tickAnimation = true
	console.log("tickAnimation: ", tickAnimation)
}
getSVGDataForSectionOne()

/* Get SVG Data for Section Two */

const getSVGDataForSectionTwo = () => {
	// console.log(svg)

	lines = []

	// use SVG API to get the path data
	svgDataSectionTwo.forEach((path, index) => {
		const pathLength = path.getTotalLength()

		// create a new array with the path data
		// Create the number of points by the path length
		let numberOfPoints = Math.floor(pathLength / 5)
		// console.log("numberOfPoints", numberOfPoints)

		let points = []

		// Get X and Y coordinates of the path
		for (let i = 0; i < numberOfPoints; i++) {
			//   let point = path.getPointAtLength(i * pathLength / numberOfPoints)
			//   points.push(point.x, point.y, point.z)
			let pointAt = (pathLength * i) / numberOfPoints
			let point = path.getPointAtLength(pointAt) // get the x and y coordinates at the point
			// console.log("point", point)
			let randomX = (Math.random() - 0.5) * 0
			let randomY = (Math.random() - 0.5) * 0
			points.push(new THREE.Vector3(point.x - 1024 + randomX, point.y - 512 + randomY, 1))
		}

		lines.push({
			id: index,
			path: path,
			length: pathLength,
			number: numberOfPoints,
			points: points,
			currentPosition: 0,
			speed: 1,
		})
	})

	// console.log("lines", lines)

	// tickAnimation = true
	// console.log("tickAnimation: ", tickAnimation)
}

/**
 * Create Particles
 */

let plane = null
let shaderMaterial = null
let shaderGeometry = null
let maxPoints
let positions
let opacity
let shaderColor = null

const particleGroup = new THREE.Group()

const addObjects = () => {
	shaderMaterial = new THREE.ShaderMaterial({
		extensions: {
			derivatives: "#extension GL_OES_standard_derivatives : enable",
		},
		side: THREE.DoubleSide,
		uniforms: {
			time: {
				value: 0,
			},
			resolution: {
				value: new THREE.Vector4(),
			},
			uColor: {
				value: new THREE.Vector3(0.0, 1.0, 1.0),
			},

			// uTexture: {
			//   value: null,
			// },
			// uDataTexture: {
			//   value: null,
			// },
		},
		// wireframe: true,
		transparent: true,
		depthTest: false,
		depthWrite: true,
		blending: THREE.AdditiveBlending,
		// blending: THREE.NormalBlending,
		vertexShader: vertex,
		fragmentShader: fragment,
	})

	shaderAltMaterial = new THREE.PointsMaterial({
		size: 40,
		sizeAttenuation: true,
		depthWrite: false,
		depthTest: false,
		blending: THREE.AdditiveBlending,
		color: 0x00ccee,
	})

	// shaderGeometry = new THREE.PlaneGeometry(2, 2, 2, 2)
	shaderGeometry = new THREE.BufferGeometry()

	// lines.forEach((line) => {
	// 	line.points.forEach((point) => {
	// 		positions.push(point.x, point.y, point.z)
	// 		opacity.push(Math.random() / 5)
	// 	})
	// })

	maxPoints = lines.length * 100
	positions = new Float32Array(maxPoints * 3)
	opacity = new Float32Array(maxPoints)
	shaderColor = new Float32Array(maxPoints * 3)

	// lines.forEach((line) => {
	// 	console.log("set color for each line")
	// 	line.points.forEach((point, index) => {
	// 		shaderColor.set([0.0, 1.0, 1.0], index * 3)
	// 	})
	// })

	// for (let i = 0; i < maxPoints; i++) {
	// 	positions.set([Math.random() * 100, Math.random() * 1000, 0], i * 3)
	// 	opacity.set([Math.random() / 5], i)
	// }

	// shaderGeometry.setAttribute("opacity", new THREE.Float32BufferAttribute(positions, 3))
	shaderGeometry.setAttribute("position", new THREE.BufferAttribute(positions, 3))
	shaderGeometry.setAttribute("opacity", new THREE.BufferAttribute(opacity, 1))
	shaderGeometry.setAttribute("color", new THREE.BufferAttribute(shaderColor, 3))

	plane = new THREE.Points(shaderGeometry, shaderMaterial)

	particleGroup.add(new THREE.Points(shaderGeometry, shaderMaterial))

	// New Position for Plane
	// plane.rotation.x = Math.PI / 2
	// plane.position.z = -75

	particleGroup.position.y = -160
}
addObjects()

scene.add(particleGroup)

// Get scroll position
let scrollPosition = 0
window.addEventListener("scroll", () => {
	scrollPosition = window.scrollY
	// console.log("scroll from app.js", scrollPosition)
})

// Update points
let opacityFaktor = 500
let activeSection = 0

// Lerping Functions
let lerpFactor = 1
function increaseLerpFactor() {
	lerpFactor = 0.1
	// setInterval to increase lerpFactor
	function updateInterval() {
		lerpFactor += lerpFactor / 50
		// Break interval
		if (lerpFactor >= 1) {
			clearInterval(intervalAccelerator)
			lerpFactor = 1
		}
	}

	let intervalAccelerator = setInterval(updateInterval, 10)
}

function lerp(start, end, factor) {
	return (1 - factor) * start + factor * end
}

const updateObjects = (scrollYPosition) => {
	// if (!tickAnimation) return

	// console.log(lerpFactor)

	// pathIndexCounter is the index of the array of paths (lines)
	let pathIndexCounter = 0

	// console.log("scrollPosition", scrollPosition)

	// lines.forEach((line) => {
	// Old System with "svgDataSectionOne.length * activeSection"
	for (let index = 0; index < svgDataSectionOne.length; index++) {
		/*
	for (let index = svgDataSectionOne.length * activeSection; index < svgDataSectionOne.length * (activeSection + 1); index++) {
		line = lines[index]
		oldLine = lines[index - svgDataSectionOne.length * activeSection]
	*/
		line = lines[index]
		sectionTwoLine = lines[index + svgDataSectionOne.length]

		// console.log("%coldLine: ", "color: #ff0000;", oldLine)

		// TODO: calculate all Lines in a Loop or somthing like that to write The code not only declarative
		// if (lerpFactor >= 1) {
		line.currentPosition += line.speed
		sectionTwoLine.currentPosition += sectionTwoLine.speed
		// }
		line.currentPosition = line.currentPosition % line.number
		sectionTwoLine.currentPosition = sectionTwoLine.currentPosition % sectionTwoLine.number

		let sectionOneindex = 0
		let point = 0
		let sectionTwoLinePoint = 0
		let sectionTwoLineIndex = 0

		for (let i = 0; i < 100; i++) {
			sectionOneindex = (line.currentPosition + i) % line.number
			point = line.points[sectionOneindex]

			sectionTwoLineIndex = (sectionTwoLine.currentPosition + i) % sectionTwoLine.number
			sectionTwoLinePoint = sectionTwoLine.points[sectionTwoLineIndex]

			/**
			 * Color
			 */

			// TODO: Event Listener für die Section mikt "Penetration-Test"
			// Alle Daten Paths werden rot (dabei ggf mit Postprocessing einen Glitch Effekt erzeugen)
			// Event listener onmouseover und onmouseleave auf CTA "Penetration-test anfragen" sorgen dafür, dass die roten Datenpaths abprallen oder kaputt gehen (opacity = 0)
			// TODO: ODER: Ich mache ganz unten auf der Seite eine Contact Section, und erst, wenn man dort ist, werden die Daten rot. Sobald man dann aber den Kontakt button hovert wird es wieder blau bzw. grün

			// COlor Particles by section
			if (scrollPosition < 1010) {
				// console.log("scrollPosition === 0", scrollPosition)
				shaderColor[pathIndexCounter * 3] = 0.0 // R-Wert
				// shaderColor[pathIndexCounter * 3 + 1] = 0.7 // G-Wert
				// shaderColor[pathIndexCounter * 3 + 2] = 0.58 // B-Wert
				shaderColor[pathIndexCounter * 3 + 1] = 0.75 // G-Wert
				shaderColor[pathIndexCounter * 3 + 2] = 1.0 // B-Wert
			}
			/*
			if (scrollPosition >= 1010) {
				// console.log("scrollPosition > 1010", scrollPosition)
				// if (line.id < 2) {
				shaderColor[pathIndexCounter * 3] = 1.0 * Math.min(scrollPosition / 320, 1) // R-Wert
				shaderColor[pathIndexCounter * 3 + 1] = 0.75 / Math.max(scrollPosition / 100, 1) // G-Wert
				shaderColor[pathIndexCounter * 3 + 2] = 1.0 / Math.max(scrollPosition / 100, 1) // B-Wert
				// shaderColor[pathIndexCounter * 3] = 1.0
				// shaderColor[pathIndexCounter * 3 + 1] = 0.0
				// shaderColor[pathIndexCounter * 3 + 2] = 0.0
				// } else {
				// 	shaderColor[pathIndexCounter * 3] = 0.0 // R-Wert
				// 	// shaderColor[pathIndexCounter * 3 + 1] = 0.7 // G-Wert
				// 	// shaderColor[pathIndexCounter * 3 + 2] = 0.58 // B-Wert
				// 	shaderColor[pathIndexCounter * 3 + 1] = 0.75 // G-Wert
				// 	shaderColor[pathIndexCounter * 3 + 2] = 1.0 // B-Wert
				// }
			}
			*/

			/**
			 * Position
			 */
			if (isSectionOneActive || isSectionTwoActive) {
				// standart without lerp
				positions.set([point.x + Math.random() * (i / 100), point.z, point.y], pathIndexCounter * 3)

				// if (lerpFactor >= 1) {
				// 	positions.set([point.x + Math.random() * (i / 50), point.z, point.y], pathIndexCounter * 3)
				// 	// console.log("point – i: ", point, i)
				// 	// console.log("sectionTwoLinePoint – i: ", sectionTwoLinePoint, i)
				// } else {
				// 	let interpolatedPointX = lerp(sectionTwoLinePoint.x, point.x, lerpFactor)
				// 	let interpolatedPointY = lerp(sectionTwoLinePoint.y, point.y, lerpFactor)
				// 	// TODO: instead of divide "Math.random" by a hard coded value use a calculated value or perlins noise
				// 	positions.set([interpolatedPointX + Math.random() * (i / 100), point.z, interpolatedPointY], pathIndexCounter * 3)
				// }

				opacity.set([i / opacityFaktor], pathIndexCounter)

				/*
				// color particles by mouse Position
				// if mouse.x if greater than point.x color is red
				if (isSectionTwoActive) {
					// if (line.id % 2 === 0) {
					// #Kreis
					let radius = 200
					let distance = Math.sqrt((mouse.x - point.x) ** 2 + (mouse.y - point.y) ** 2)

					// Überprüfe, ob der Abstand kleiner als der Radius ist
					if (distance < radius) {
						shaderColor[pathIndexCounter * 3] = 1.0 // R-Wert
						shaderColor[pathIndexCounter * 3 + 1] = 0.0 // G-Wert
						shaderColor[pathIndexCounter * 3 + 2] = 0.0 // B-Wert
					}
					// }
				}
				*/
			}
			if (isSectionThreeActive) {
				// console.log("isSectionTwoActive: ", isSectionTwoActive)

				// #Standart
				// positions.set([oldPoint.x + Math.random() * (i / 50), oldPoint.z, oldPoint.y], pathIndexCounter * 3)

				/* Working Transition 
				if (lerpFactor >= 1) {
					positions.set([point.x + Math.random() * (i / 50), point.z, point.y], pathIndexCounter * 3)
				} else {
					let interpolatedPointX = lerp(oldPoint.x, point.x, lerpFactor)
					let interpolatedPointY = lerp(oldPoint.y, point.y, lerpFactor)
					positions.set([interpolatedPointX + Math.random() * (i / 50), point.z, interpolatedPointY], pathIndexCounter * 3)
				}
				*/

				if (lerpFactor >= 1) {
					positions.set([sectionTwoLinePoint.x + Math.random() * (i / 100), sectionTwoLinePoint.z, sectionTwoLinePoint.y], pathIndexCounter * 3)
					// console.log("point – i: ", point, i)
					// console.log("sectionTwoLinePoint – i: ", sectionTwoLinePoint, i)
				} else {
					let interpolatedPointX = lerp(point.x, sectionTwoLinePoint.x, lerpFactor)
					let interpolatedPointY = lerp(point.y, sectionTwoLinePoint.y, lerpFactor)
					// TODO: instead of divide "Math.random" by a hard coded value use a calculated value or perlins noise
					positions.set([interpolatedPointX + Math.random() * (i / 100), point.z, interpolatedPointY], pathIndexCounter * 3)
				}

				// #Try to learp with "lerpVectors"
				// lerpedPosition = new THREE.Vector3().lerpVectors(
				// 	new THREE.Vector3(point.x, point.z, point.y),
				// 	new THREE.Vector3(point.x + Math.random() * (i / 50), point.z, point.y),
				// 	(line.currentPosition % 1) * 0.1
				// )
				// positions.set([lerpedPosition.x, lerpedPosition.y, lerpedPosition.z], pathIndexCounter * 3)

				// #Try to learp with "alpha"
				// let alpha = line.currentPosition % 1
				// lerpedPosition = new THREE.Vector3(point.x + alpha * Math.random() * (i / 50), point.z, point.y)
				// positions.set([lerpedPosition.x, lerpedPosition.y, lerpedPosition.z], pathIndexCounter * 3)

				opacity.set([i / opacityFaktor], pathIndexCounter)

				// console.log("lerpedPosition", lerpedPosition)
				// console.log("lerpedPosition", lerpedPosition)
			}

			// Was working to reset color for each particle
			// shaderMaterial.uniforms.uColor.value = new THREE.Vector3(0.0, 1.0, 1.0) // shaderColor // Beispiel: türkise Farbe

			pathIndexCounter++
		}
	}

	shaderGeometry.attributes.position.needsUpdate = true
	shaderGeometry.attributes.position.array = positions

	shaderGeometry.attributes.opacity.needsUpdate = true
	shaderGeometry.attributes.opacity.array = opacity

	// shaderMaterial.uniforms.uColor.value = new THREE.Vector3(0.0, 1.0, 1.0) // shaderColor // Beispiel: türkise Farbe
	// console.log("shaderGeometry.attributes.uColor", shaderGeometry.attributes.uColor)
	shaderGeometry.attributes.color.needsUpdate = true
	shaderGeometry.attributes.color.array = shaderColor

	// Aktualisieren Sie das Farb-Uniform in Ihrem ShaderMaterial
	// shaderMaterial.uniforms.uColor.value = new THREE.Vector3(0.0, 1.0, 1.0) // Türkis

	// Aktualisieren Sie das Farb-Attribut in der Shader-Geometrie
	// shaderMaterial.needsUpdate = true
	// shaderMaterial.uniforms.uColor.value = shaderColor
}

/**
 * Main Onject
 */

let sphereMaterial = new THREE.MeshPhysicalMaterial({
	color: 0x698dbf, // 0x3c5a83,
	// color: 0xff0000, // 0x3c5a83,
	roughness: 0.4,
	metalness: 0.6,
	// transparent: true,
	opacity: 1,
})

// const mainSphere = new THREE.Mesh(new THREE.CylinderGeometry(50, 50, 30, 48), sphereMaterial)
// mainSphere.position.y = -160
// scene.add(mainSphere)

// Lights
// Add point light
const pointLight = new THREE.PointLight(0xffffff, 1)
pointLight.position.x = 500
pointLight.position.y = 400
pointLight.position.z = 1000
scene.add(pointLight)

// Add ambient light
const ambientLight = new THREE.AmbientLight(0xffffff, 0.5)
scene.add(ambientLight)

/**
 * Animate
 */
// let rotation = 0
// const rotateObject = () => {
// 	rotation += 0.005
// 	mainSphere.rotation.y = rotation
// }
const updateCamera = () => {
	// mainSphere.position.x = -mouse.x / 500
	// mainSphere.rotation.x = mouse.y * mouseStrength
	// mainSphere.rotation.y = rotation + mouse.x * mouseStrength * 3

	// lerp camera.position.x to mouse.x
	camera.position.x += (mouse.x / 16 - camera.position.x) * 0.1
	camera.position.y += (mouse.y / 9 - camera.position.y) * 0.1

	// particleGroup.position.y = -160 - scrollPosition / 2
	if (scrollPosition < 10) {
		if (!isSectionOneActive) {
			isSectionOneActive = true
			isSectionTwoActive = false
			activeSection = 0
			increaseLerpFactor()
			// getSVGDataForSectionOne()
		}
		opacityFaktor += (450 - opacityFaktor) * 0.09
		particleGroup.position.y += (-200 - particleGroup.position.y) * 0.05
		// particleGroup.position.x += (-300 - particleGroup.position.x) * 0.05
		particleGroup.position.z += (-100 - particleGroup.position.z) * 0.05
		particleGroup.rotation.x += (0 - particleGroup.rotation.x) * 0.05
	}

	if (scrollPosition > 10 && scrollPosition < 1009) {
		if (!isSectionTwoActive) {
			isSectionOneActive = false
			isSectionTwoActive = true
			activeSection = 1
			increaseLerpFactor()
			// getSVGDataForSectionTwo()
		}

		// lerp to opacity factor 600
		// opacityFaktor += (500 - opacityFaktor) * 0.01
		opacityFaktor += (1000 - opacityFaktor) * 0.01

		// lerp particleGroup.position.y to 0
		particleGroup.position.y += (10 - particleGroup.position.y) * 0.05
		// particleGroup.position.x += (-300 - particleGroup.position.x) * 0.05
		particleGroup.position.z += (0 - particleGroup.position.z) * 0.05
		particleGroup.rotation.x += (Math.PI / 2 - particleGroup.rotation.x) * 0.05
	}

	// if (scrollPosition < 1010) {
	// 	opacityFaktor += (500 - opacityFaktor) * 0.05
	// 	// lerp particleGroup.position.y to -160
	// 	particleGroup.position.y += (-160 - particleGroup.position.y) * 0.05
	// 	// particleGroup.position.x += (0 - particleGroup.position.x) * 0.05
	// 	particleGroup.position.z += (0 - particleGroup.position.z) * 0.05
	// 	particleGroup.rotation.x += (0 - particleGroup.rotation.x) * 0.05
	// } else {
	// 	tickAnimation = true
	// 	opacityFaktor += (350 - opacityFaktor) * 0.09
	// }

	// camera.position.x = mouse.x / 20
	// camera.position.y = mouse.y / 20

	// camera.position.x = Math.sin(time) * 3
	// camera.position.z = Math.cos(time) * 3
	camera.lookAt(0, 10, 0)
}

const clock = new THREE.Clock()
const tick = () => {
	const elapsedTime = clock.getElapsedTime()

	// Update objects
	updateObjects(scrollPosition)

	// rotateObject()

	updateCamera()

	// Update controls
	// controls.update()

	// Render
	renderer.render(scene, camera)

	// Call tick again on the next frame
	window.requestAnimationFrame(tick)
}

tick()

/**
 * Resize window
 */
window.addEventListener("resize", () => {
	// Update sizes
	sizes.width = window.innerWidth
	sizes.height = window.innerHeight

	// Update camera
	camera.aspect = sizes.width / sizes.height
	camera.updateProjectionMatrix()

	// Update renderer
	renderer.setSize(sizes.width, sizes.height)
	renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/*
import * as THREE from "three"
import fragment from "./shader/fragment.glsl"
import vertex from "./shader/vertex.glsl"
import GUI from "lil-gui"

function clamp(number, min, max) {
	return Math.max(min, Math.min(number, max))
}

export default class Sketch {
	constructor(options) {
		this.scene = new THREE.Scene()

		this.container = options.dom
		this.img = this.container.querySelector("img")
		this.width = this.container.offsetWidth
		this.height = this.container.offsetHeight
		this.renderer = new THREE.WebGLRenderer()
		this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
		this.renderer.setSize(this.width, this.height)
		this.renderer.setClearColor(0xeeeeee, 1)
		this.renderer.physicallyCorrectLights = true
		this.renderer.outputEncoding = THREE.sRGBEncoding

		this.container.appendChild(this.renderer.domElement)

		this.camera = new THREE.PerspectiveCamera(70, window.innerWidth / window.innerHeight, 0.1, 100)

		var frustumSize = 1
		var aspect = window.innerWidth / window.innerHeight
		this.camera = new THREE.OrthographicCamera(frustumSize / -2, frustumSize / 2, frustumSize / 2, frustumSize / -2, -1000, 1000)
		this.camera.position.set(0, 0, 2)

		this.time = 0

		this.mouse = {
			x: 0,
			y: 0,
			prevX: 0,
			prevY: 0,
			vX: 0,
			vY: 0,
		}

		this.isPlaying = true
		this.settings()
		this.addObjects()
		this.resize()
		this.render()
		this.setupResize()

		this.mouseEvents()
	}

	getValue(val) {
		return parseFloat(this.container.getAttribute("data-" + val))
	}

	mouseEvents() {
		window.addEventListener("mousemove", (e) => {
			this.mouse.x = e.clientX / this.width
			this.mouse.y = e.clientY / this.height

			// console.log(this.mouse.x,this.mouse.y)

			this.mouse.vX = this.mouse.x - this.mouse.prevX
			this.mouse.vY = this.mouse.y - this.mouse.prevY

			this.mouse.prevX = this.mouse.x
			this.mouse.prevY = this.mouse.y

			// console.log(this.mouse.vX,'vx')
		})
	}

	settings() {
		let that = this
		this.settings = {
			grid: this.getValue("grid") || 34,
			mouse: this.getValue("mouse") || 0.25,
			strength: this.getValue("strength") || 1,
			relaxation: this.getValue("relaxation") || 0.9,
		}

		this.gui = new GUI()

		this.gui.add(this.settings, "grid", 2, 1000, 1).onFinishChange(() => {
			this.regenerateGrid()
		})
		this.gui.add(this.settings, "mouse", 0, 1, 0.01)
		this.gui.add(this.settings, "strength", 0, 1, 0.01)
		this.gui.add(this.settings, "relaxation", 0, 1, 0.01)
	}

	setupResize() {
		window.addEventListener("resize", this.resize.bind(this))
	}

	resize() {
		this.width = this.container.offsetWidth
		this.height = this.container.offsetHeight
		this.renderer.setSize(this.width, this.height)
		this.camera.aspect = this.width / this.height

		// image cover
		this.imageAspect = 1 / 1.5
		let a1
		let a2
		if (this.height / this.width > this.imageAspect) {
			a1 = (this.width / this.height) * this.imageAspect
			a2 = 1
		} else {
			a1 = 1
			a2 = this.height / this.width / this.imageAspect
		}

		this.material.uniforms.resolution.value.x = this.width
		this.material.uniforms.resolution.value.y = this.height
		this.material.uniforms.resolution.value.z = a1
		this.material.uniforms.resolution.value.w = a2

		this.camera.updateProjectionMatrix()
		this.regenerateGrid()
	}

	regenerateGrid() {
		this.size = this.settings.grid

		const width = this.size
		const height = this.size

		const size = width * height
		const data = new Float32Array(3 * size)
		const color = new THREE.Color(0xffffff)

		const r = Math.floor(color.r * 255)
		const g = Math.floor(color.g * 255)
		const b = Math.floor(color.b * 255)

		for (let i = 0; i < size; i++) {
			let r = Math.random() * 255 - 125
			let r1 = Math.random() * 255 - 125

			const stride = i * 3

			data[stride] = r
			data[stride + 1] = r1
			data[stride + 2] = r
		}

		// used the buffer to create a DataTexture

		this.texture = new THREE.DataTexture(data, width, height, THREE.RGBFormat, THREE.FloatType)

		this.texture.magFilter = this.texture.minFilter = THREE.NearestFilter

		if (this.material) {
			this.material.uniforms.uDataTexture.value = this.texture
			this.material.uniforms.uDataTexture.value.needsUpdate = true
		}
	}

	addObjects() {
		this.regenerateGrid()
		let texture = new THREE.Texture(this.img)
		texture.needsUpdate = true
		this.material = new THREE.ShaderMaterial({
			extensions: {
				derivatives: "#extension GL_OES_standard_derivatives : enable",
			},
			side: THREE.DoubleSide,
			uniforms: {
				time: {
					value: 0,
				},
				resolution: {
					value: new THREE.Vector4(),
				},
				uTexture: {
					value: texture,
				},
				uDataTexture: {
					value: this.texture,
				},
			},
			vertexShader: vertex,
			fragmentShader: fragment,
		})

		this.geometry = new THREE.PlaneGeometry(1, 1, 1, 1)

		this.plane = new THREE.Mesh(this.geometry, this.material)
		this.scene.add(this.plane)
	}

	updateDataTexture() {
		let data = this.texture.image.data
		for (let i = 0; i < data.length; i += 3) {
			data[i] *= this.settings.relaxation
			data[i + 1] *= this.settings.relaxation
		}

		let gridMouseX = this.size * this.mouse.x
		let gridMouseY = this.size * (1 - this.mouse.y)
		let maxDist = this.size * this.settings.mouse
		let aspect = this.height / this.width

		for (let i = 0; i < this.size; i++) {
			for (let j = 0; j < this.size; j++) {
				let distance = (gridMouseX - i) ** 2 / aspect + (gridMouseY - j) ** 2
				let maxDistSq = maxDist ** 2

				if (distance < maxDistSq) {
					let index = 3 * (i + this.size * j)

					let power = maxDist / Math.sqrt(distance)
					power = clamp(power, 0, 10)
					// if(distance <this.size/32) power = 1;
					// power = 1;

					data[index] += this.settings.strength * 100 * this.mouse.vX * power
					data[index + 1] -= this.settings.strength * 100 * this.mouse.vY * power
				}
			}
		}

		this.mouse.vX *= 0.9
		this.mouse.vY *= 0.9
		this.texture.needsUpdate = true
	}

	render() {
		if (!this.isPlaying) return
		this.time += 0.05
		this.updateDataTexture()
		this.material.uniforms.time.value = this.time
		requestAnimationFrame(this.render.bind(this))
		this.renderer.render(this.scene, this.camera)
	}
}

new Sketch({
	dom: document.getElementById("canvasContainer"),
})
*/
